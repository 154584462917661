import { inject } from 'vue';

import { DI_API_CLIENT } from '@/constants/injection-constants.ts';
import type IApiClient from '@/types/api/api-client-interface.ts';

export function useApi(): IApiClient {
  const api = inject(DI_API_CLIENT);

  if (!api) {
    throw new Error('FATAL ERROR: API client is not provided.');
  }

  return api;
}
