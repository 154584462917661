<script setup lang="ts">
import {
  type ContentBlockVariants,
  contentBlockVariants,
} from '@/components/ui/content-block/index.ts';
import { cn } from '@/lib/utils';

interface Props {
  variant?: ContentBlockVariants['variant'];
  class?: string;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'default',
  class: '',
});
</script>

<template>
  <div v-bind="$attrs" :class="cn(contentBlockVariants({ variant }), props.class)">
    <slot />
  </div>
</template>
