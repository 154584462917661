import { ref, type Ref } from 'vue';

import { ResetPasswordDialogState } from '@/types/auth/reset-password-dialog.ts';

const isOpen: Ref<boolean> = ref(false);

const currentState: Ref<ResetPasswordDialogState> = ref(ResetPasswordDialogState.sendEmail);

export function useResetPasswordDialog() {
  const changeModalState = (state: ResetPasswordDialogState) => {
    currentState.value = state;
  };

  const openDialog = (state: ResetPasswordDialogState) => {
    isOpen.value = true;
    currentState.value = state;
  };

  const closeDialog = () => {
    isOpen.value = false;
  };

  return {
    openDialog,
    closeDialog,
    changeModalState,
    isOpen,
    currentState,
  };
}
