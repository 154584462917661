import { useMutation, useQueryClient } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useApi } from '@/hooks/use-api';
import { validateApiSchema } from '@/lib/zod-helpers.ts';
import ApiError from '@/models/api/api-error.ts';
import { type User, UserSchema } from '@/schemas/users/user-schema.ts';

type GoogleLoginVariables = { credential: string };

export function useLoginGoogleMutation() {
  const api = useApi();
  const queryClient = useQueryClient();
  const { login, identifyUserOnVendorServices } = useAuth();

  return useMutation<User, ApiError, GoogleLoginVariables>({
    mutationFn: async (query: GoogleLoginVariables) => {
      const response = await api.get<User>('auth/google/authorize', query);

      validateApiSchema(UserSchema, response.data);

      return response.data;
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });

      queryClient.setQueryData([API_QUERY_KEY_USER_ME], data);
      identifyUserOnVendorServices(data);

      await login();
    },
  });
}
