import { ref, type Ref } from 'vue';

import { useAuth } from '@/hooks/auth/use-auth.ts';
import { DepositDialogState } from '@/types/invest/deposit-dialog-state-enum.ts';

const isOpen: Ref<boolean> = ref(false);
const currentState: Ref<DepositDialogState> = ref(DepositDialogState.Intro);

export function useDepositDialog() {
  const { isAuthenticated } = useAuth();

  const changeModalState = (state: DepositDialogState) => {
    currentState.value = state;
  };

  const openDialog = (state: DepositDialogState) => {
    if (!isAuthenticated.value) {
      isOpen.value = true;
      currentState.value = state;
    }
  };

  const closeDialog = () => {
    isOpen.value = false;
  };

  return {
    openDialog,
    closeDialog,
    changeModalState,
    isOpen,
    currentState,
  };
}
