import { useMutation, useQueryClient } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useApi } from '@/hooks/use-api';
import { validateApiSchema } from '@/lib/zod-helpers.ts';
import ApiError from '@/models/api/api-error.ts';
import { type BankAccount, BankAccountSchema } from '@/schemas/users/bank-account-schema.ts';
import { type User } from '@/schemas/users/user-schema.ts';

type BankAccountVariable = {
  userId: string;
  bankName?: string;
  accountNumber?: string;
  bankAccountId: string;
  default?: boolean;
};

export function useUpdateUserBankAccountMutation() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<BankAccount, ApiError, BankAccountVariable>({
    mutationFn: async (data: BankAccountVariable) => {
      const response = await api.patch<BankAccount>(
        `/users/${data.userId}/bank-accounts/${data.bankAccountId}`,
        {
          bankName: data.bankName,
          accountNumber: data.accountNumber,
          default: data.default,
        },
      );

      validateApiSchema(BankAccountSchema, response.data);

      return response.data;
    },
    onError: (_error: Error, _variables, context: any) => {
      if (context) {
        queryClient.setQueryData<User>([API_QUERY_KEY_USER_ME], context.previousUser);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });
    },
  });
}
