<script setup lang="ts">
import { type FunctionalComponent, isVNode, type SVGAttributes } from 'vue';

import { useToast } from './use-toast';

import {
  Toast,
  ToastDescription,
  type ToastProps,
  ToastProvider,
  ToastTitle,
  ToastViewport,
  ToastAction,
} from '.';

import ArrowRight from '~icons/iconoir/arrow-right';
import CheckCircle from '~icons/iconoir/check-circle';
import InfoCircle from '~icons/iconoir/info-circle';
import WarningHexagon from '~icons/iconoir/warning-hexagon';
import WarningTriangle from '~icons/iconoir/warning-triangle';
import XmarkIcon from '~icons/iconoir/xmark';

const { toasts, dismiss } = useToast();

const getIcon = (
  variant: ToastProps['variant'],
): FunctionalComponent<SVGAttributes, any, any, any> | undefined => {
  if (!variant) return;

  const icons = {
    info: InfoCircle,
    success: CheckCircle,
    warning: WarningTriangle,
    error: WarningHexagon,
  };

  return icons[variant as keyof typeof icons];
};
</script>

<template>
  <ToastProvider>
    <Toast v-for="toast in toasts" :key="toast.id" v-bind="toast">
      <div class="grid gap-2">
        <ToastTitle v-if="toast.title">
          <div class="flex items-center gap-4">
            <component :is="getIcon(toast.variant)" class="size-6" />
            {{ toast.title }}
          </div>
          <XmarkIcon
            v-if="toast.cancelable"
            class="size-6 cursor-pointer"
            @click="dismiss(toast.id)"
          />
        </ToastTitle>
        <template v-if="toast.description">
          <ToastDescription v-if="isVNode(toast.description)">
            <component :is="toast.description" />
          </ToastDescription>
          <ToastDescription v-else>
            {{ toast.description }}
          </ToastDescription>
        </template>
        <ToastAction v-if="toast.link" :alt-text="toast.link.text" as-child>
          <a :href="toast.link.href" target="_blank">
            {{ toast.link.text }}
            <ArrowRight class="size-4" />
          </a>
        </ToastAction>
      </div>
    </Toast>
    <ToastViewport />
  </ToastProvider>
</template>
