import { z } from 'zod';

import { OrderSchema } from '@/schemas/invest/order-schema';

export const BuyOrderSchema = OrderSchema.extend({
  isLocked: z.boolean(),
  availableBondsToSell: z.number(),
  assetBondRatio: z.number(),
  custodyFeesDue: z.number(),
  custodyFeesPaid: z.number(),
  availabilityDate: z.string().datetime(),
});

export type BuyOrder = z.infer<typeof BuyOrderSchema>;
