import { useMutation } from '@tanstack/vue-query';

import { useApi } from '@/hooks/use-api';
import { validateApiSchema } from '@/lib/zod-helpers';
import ApiError from '@/models/api/api-error.ts';
import {
  type AddressAutocompleteResults,
  AddressAutocompleteResultsSchema,
} from '@/schemas/kyc/address-autocomplete-results-schema';

export function useAddressAutocompleteMutation() {
  const api = useApi();

  return useMutation<AddressAutocompleteResults, ApiError, string>({
    mutationFn: async (query: string) => {
      const response = await api.get<AddressAutocompleteResults>('/kyc/address/search', {
        query,
      });
      validateApiSchema(AddressAutocompleteResultsSchema, response.data);

      return response.data;
    },
  });
}
