import { ref, type Ref } from 'vue';

import { useAuth } from '@/hooks/auth/use-auth.ts';
import { AuthDialogState } from '@/types/auth/auth-dialog-state-type.ts';

const isOpen: Ref<boolean> = ref(false);
const currentState: Ref<AuthDialogState> = ref(AuthDialogState.Login);

export function useAuthDialog() {
  const { isAuthenticated } = useAuth();

  const changeModalState = (state: AuthDialogState) => {
    currentState.value = state;
  };

  const openDialog = (state: AuthDialogState) => {
    if (!isAuthenticated.value) {
      isOpen.value = true;
      currentState.value = state;
    }
  };

  const closeDialog = () => {
    isOpen.value = false;
  };

  return {
    openDialog,
    closeDialog,
    changeModalState,
    isOpen,
    currentState,
  };
}
