import { type App } from 'vue';

import { type IAnalyticsHandler } from '@/types/analytics/analytics-handler-interface.ts';
import { type JsonObject } from '@/types/json-type.ts';

class Analytics {
  private readonly handlers: IAnalyticsHandler[] = [];

  constructor(handlers: IAnalyticsHandler[]) {
    if (import.meta.env.VITE_APP_ENV === 'development') {
      return;
    }

    this.handlers = handlers;
  }

  install(app: App): void {
    if (import.meta.env.VITE_APP_ENV === 'development') {
      return;
    }

    this.handlers.forEach((handler: IAnalyticsHandler) => handler.install(app));
  }

  trackPageView(url: string | undefined): void {
    for (const handler of this.handlers) {
      handler.trackPageView(url);
    }
  }

  trackEvent(name: string, data?: JsonObject): void {
    for (const handler of this.handlers) {
      handler.trackEvent(name, data);
    }
  }

  identify(userId: string, data?: JsonObject): void {
    for (const handler of this.handlers) {
      handler.identify(userId, data);
    }
  }

  executeRouteHandlers(url: string, properties?: object): void {
    const routeHandlers = this.handlers.map((handler) => handler.getRouteHandler);

    routeHandlers.forEach((handler) => handler(url, properties));
  }

  logout(): void {
    for (const handler of this.handlers) {
      handler.logout?.();
    }
  }
}

export default Analytics;
