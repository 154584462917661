import { type ApiErrorData } from '@/types/api/api-error-data-type.ts';

export default class ApiError extends Error {
  status: number;
  data: any;

  constructor(message: string, status: number, data?: ApiErrorData) {
    super(message);
    this.name = 'ApiError';
    this.status = status;
    this.data = data;
  }
}
