<script setup lang="ts">
import { toLower } from 'lodash-es';
import { type PropType } from 'vue';

export type CountryFlagSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const sizeToClass: Record<CountryFlagSize, string> = {
  xs: 'w-3',
  sm: 'w-4',
  md: 'w-5',
  lg: 'w-6',
  xl: 'w-7',
};

const props = defineProps({
  alpha2Code: {
    type: String,
    required: true,
  },
  size: {
    type: String as PropType<CountryFlagSize>,
    default: 'sm',
  },
});
</script>

<template>
  <div>
    <img
      :class="sizeToClass[size]"
      :src="`/imgs/flags-icon/${toLower(props.alpha2Code)}.png`"
      :alt="`${props.alpha2Code} flag`"
    />
  </div>
</template>
