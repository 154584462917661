<script setup lang="ts">
import LocaleSelector from '@/components/LocaleSelector.vue';
import { TButton } from '@/components/ui/button';
import { TDialogHeader, TDialogContentBodyCentered } from '@/components/ui/dialog';
import { useToast } from '@/components/ui/toast';
import { TWaveLoader } from '@/components/ui/wave-loader';
import { useKycDialog } from '@/hooks/kyc/use-kyc-dialog.ts';
import { useKycProcess } from '@/hooks/kyc/use-kyc-process.ts';
import ApiError from '@/models/api/api-error.ts';
import { useSendVerifyEmailMutation } from '@/queries/auth/use-send-verify-email-mutation.ts';
import { type User } from '@/schemas/users/user-schema.ts';
import { KycDialogState } from '@/types/kyc/kyc-dialog-state-enum.ts';

const props = defineProps<{
  user: User;
}>();

const { closeDialog, changeModalState } = useKycDialog();

const { toast } = useToast();
const { updateLastEmailCodeGeneratedAt } = useKycProcess(props.user);

const onSuccess = (): void => {
  updateLastEmailCodeGeneratedAt(new Date());
  changeModalState(KycDialogState.EmailVerification);
};

const onError = (error: ApiError): void => {
  toast({
    title: error.data.message,
    variant: 'error',
  });
};

const { mutateAsync: sendVerifyEmailMutation, isPending: isSendVerifyEmailPending } =
  useSendVerifyEmailMutation();

const handleVerification = async () => {
  try {
    await sendVerifyEmailMutation();
    onSuccess();
  } catch (e) {
    onError(e as ApiError);
  }
};
</script>

<template>
  <TDialogHeader class="flex flex-row justify-between bg-white lg:bg-beige-100">
    <div class="h-full w-fit content-center text-primary-900">
      <LocaleSelector />
    </div>
    <template #modal-close>
      <button class="cursor-pointer text-sm font-semibold text-primary-700" @click="closeDialog">
        {{ $t('common.continueLater') }}
      </button>
    </template>
  </TDialogHeader>
  <TDialogContentBodyCentered class="gap-3">
    <h4 class="mb-7 font-bold">{{ $t('dialog.kyc.intro.title') }}</h4>
    <div class="flex flex-col gap-3 text-sm font-medium text-primary-600">
      <p>
        {{ $t('dialog.kyc.intro.description1') }}
      </p>
      <ul class="ml-3 flex list-outside list-disc flex-col gap-2">
        <li>
          {{ $t('dialog.kyc.intro.description2') }}
        </li>
        <li>
          {{ $t('dialog.kyc.intro.description3') }}
        </li>
        <li>
          {{ $t('dialog.kyc.intro.description4') }}
        </li>
      </ul>
      <p>
        {{ $t('dialog.kyc.intro.description5') }}
      </p>
    </div>
    <TButton class="mt-7" @click="handleVerification">
      <p v-if="!isSendVerifyEmailPending">{{ $t('common.continue') }}</p>
      <TWaveLoader v-else size="sm" class="bg-white" />
    </TButton>
  </TDialogContentBodyCentered>
</template>
