import { useQuery, type UseQueryReturnType } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useApi } from '@/hooks/use-api.ts';
import { validateApiSchema } from '@/lib/zod-helpers.ts';
import ApiError from '@/models/api/api-error.ts';
import { type User, UserSchema } from '@/schemas/users/user-schema.ts';

export function useUserMeQuery(): UseQueryReturnType<User, Error> {
  const api = useApi();
  const { isAuthenticated, identifyUserOnVendorServices } = useAuth();

  return useQuery<User, Error>({
    queryKey: [API_QUERY_KEY_USER_ME],
    queryFn: async () => {
      try {
        const response = await api.get<User>('/users/me');
        validateApiSchema(UserSchema, response.data);
        identifyUserOnVendorServices(response.data);
        return response.data;
      } catch (error) {
        throw error as ApiError;
      }
    },
    enabled: isAuthenticated,
    meta: { authRequired: true },
  });
}
