import { ref, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useAuth } from '@/hooks/auth/use-auth.ts';
import { WithdrawDialogState } from '@/types/invest/withdraw-dialog-state-enum.ts';
import { type VerticalStepperStep } from '@/types/vertical-stepper-step';

const isOpen: Ref<boolean> = ref(false);
const currentState: Ref<WithdrawDialogState> = ref(WithdrawDialogState.Creation);

export function useWithdrawDialog() {
  const { isAuthenticated } = useAuth();
  const { t: $t } = useI18n();

  const changeModalState = (state: WithdrawDialogState) => {
    currentState.value = state;
  };

  const openDialog = (state: WithdrawDialogState) => {
    if (!isAuthenticated.value) {
      isOpen.value = true;
      currentState.value = state;
    }
  };

  const closeDialog = () => {
    isOpen.value = false;
  };

  const steps: VerticalStepperStep[] = ['requestReceived', 'confirmation'].map((key) => ({
    key,
    label: $t(`component.withdraw.steps.${key}`),
    downConnectorClass: 'bg-primary-300',
    dotClass: 'bg-primary-300',
    upConnectorClass: 'bg-primary-300',
  }));

  return {
    openDialog,
    closeDialog,
    changeModalState,
    isOpen,
    currentState,
    steps,
  };
}
