import { default as Intercom, trackEvent, shutdown, update } from '@intercom/messenger-js-sdk';
import { type App } from 'vue';

import { type User } from '@/schemas/users/user-schema.ts';
import { type IAnalyticsHandler } from '@/types/analytics/analytics-handler-interface.ts';
import { type JsonObject } from '@/types/json-type.ts';

export class IntercomAnalytics implements IAnalyticsHandler {
  private static _init?: boolean;

  install(_app: App): void {
    if (!IntercomAnalytics._init) {
      const intercomToken = import.meta.env.VITE_INTERCOM_TOKEN;

      Intercom({
        app_id: intercomToken,
      });

      IntercomAnalytics._init = true;
    }
  }

  identify(userId: string, data: User): void {
    if (IntercomAnalytics._init) {
      const name =
        data?.kyc?.firstName || data?.kyc?.lastName
          ? `${data?.kyc?.firstName} ${data?.kyc?.lastName}`
          : data.email;

      update({
        user_id: userId,
        email: data.email,
        name,
      });
    }
  }

  getRouteHandler = (): any => {
    // Intercom do not track page view
  };

  trackEvent(name: string, data: JsonObject, _userId?: string): void {
    if (IntercomAnalytics._init) {
      trackEvent(name, { ...data });
    }
  }

  trackPageView(): void {
    // Intercom do not track page view
  }

  logout(): void {
    if (IntercomAnalytics._init) {
      shutdown();
    }
  }
}
