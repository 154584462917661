import { useMutation, useQueryClient } from '@tanstack/vue-query';

import { API_QUERY_KEY_USER_ME } from '@/constants/api-query-key-constants.ts';
import { useApi } from '@/hooks/use-api';
import ApiError from '@/models/api/api-error.ts';
import { type UpdateKycFormData } from '@/models/kyc/update-kyc-form-data-type.ts';
import { type JsonResponse } from '@/types/api/json-response-type.ts';

export function useUpdateKycMutation() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation<JsonResponse, ApiError, { form: UpdateKycFormData; kycId: string }>({
    mutationFn: async ({ form, kycId }: { form: UpdateKycFormData; kycId: string }) => {
      const response = await api.patch<JsonResponse>(`/kyc/${kycId}`, form);

      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [API_QUERY_KEY_USER_ME] });
    },
  });
}
