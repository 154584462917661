import { useMutation } from '@tanstack/vue-query';

import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useApi } from '@/hooks/use-api';
import ApiError from '@/models/api/api-error.ts';
import { type JsonResponse } from '@/types/api/json-response-type.ts';

export function useLogoutMutation() {
  const api = useApi();
  const { logout } = useAuth();

  return useMutation<JsonResponse, ApiError, void>({
    mutationFn: async () => {
      const response = await api.post<JsonResponse>('auth/logout', {});

      return response.data;
    },
    onSuccess: async () => {
      await logout();
    },
  });
}
