import debounce from 'lodash-es/debounce';
import { computed, ref, type Ref } from 'vue';

import { useAddressAutocompleteMutation } from '@/queries/kyc/use-address-autocomplete-mutation';
import { type AddressAutocompleteResults } from '@/schemas/kyc/address-autocomplete-results-schema';

export function useAddressAutocomplete() {
  const { mutateAsync: addressAutocompleteMutate, isPending: isAddressAutocompletePending } =
    useAddressAutocompleteMutation();

  const addressAutocompleteData: Ref<AddressAutocompleteResults | null> = ref(null);

  const updateDebouncedAddress = debounce(async (newValue?: string) => {
    if (newValue && newValue.length >= 3) {
      addressAutocompleteData.value = await addressAutocompleteMutate(newValue);
    }
  }, 300);

  const addressSuggestions = computed(() => {
    if (!addressAutocompleteData.value) return [];

    return addressAutocompleteData.value.results.map((item, index) => ({
      value: index,
      label: item.rawAddress,
    }));
  });

  return {
    addressSuggestions,
    updateDebouncedAddress,
    isAddressAutocompletePending,
    addressAutocompleteData,
  };
}
