import { type App } from 'vue';

import { DI_ANALYTICS_SERVICE } from '@/constants/injection-constants';
import AnalyticsService from '@/services/analytics/analytics-service';
import { IntercomAnalytics } from '@/services/analytics/intercom-handler';
import { PosthogAnalytics } from '@/services/analytics/posthog-handler';

export default {
  install(app: App): void {
    const analytics = new AnalyticsService([new PosthogAnalytics(), new IntercomAnalytics()]);

    analytics.install(app);

    app.provide(DI_ANALYTICS_SERVICE, analytics);
  },
};
