import { cva, type VariantProps } from 'class-variance-authority';

export { default as TContentBlock } from './TContentBlock.vue';
export { default as TContentBlockHeader } from './TContentBlockHeader.vue';
export { default as TContentBlockBody } from './TContentBlockBody.vue';
export { default as TContentBlockFooter } from './TContentBlockFooter.vue';

export const contentBlockPaddings = {
  default: 'px-6',
  fluid: 'px-0',
  xs: 'px-2',
  sm: 'px-4',
  lg: 'px-8',
  xl: 'px-10',
};

export const contentBlockSpacings = {
  default: 'my-6',
  fluid: 'my-0',
  xs: 'my-2',
  sm: 'my-4',
  lg: 'my-8',
  xl: 'my-10',
};

export const contentBlockBottomSpacings = {
  default: 'mb-6',
  fluid: 'mb-0',
  xs: 'mb-2',
  sm: 'mb-4',
  lg: 'mb-8',
  xl: 'mb-10',
};

export const contentBlockVariants = cva('w-full rounded-lg', {
  variants: {
    variant: {
      default: 'bg-white',
      gray: 'bg-beige-100 border border-beige-400',
    },
  },
});

export type ContentBlockVariants = VariantProps<typeof contentBlockVariants>;
