import {
  type NavigationGuardNext,
  type RouteLocationNormalizedGeneric,
  type RouteLocationNormalizedLoadedGeneric,
} from 'vue-router';

import { useAnalytics } from '@/hooks/use-analytics.ts';

export function analyticsGuard(
  to: RouteLocationNormalizedGeneric,
  _from: RouteLocationNormalizedLoadedGeneric,
  next: NavigationGuardNext,
): void {
  const analytics = useAnalytics();
  const fullUrl = `${window.location.origin}${to.fullPath}`;

  analytics.executeRouteHandlers(fullUrl, {});

  next();
}
