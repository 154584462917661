import { z } from 'zod';

const OrderStatusSchema = z.enum([
  'CREATED',
  'CONTRACT_GENERATED',
  'SIGNATURE_DONE',
  'PROCESSED',
  'CANCELED',
]);

const OrderTypeSchema = z.enum(['BUY', 'SELL']);
const CustodyFeesPeriodStatusEnumSchema = z.enum(['ONGOING', 'PAST']);

export const BatchSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  code: z.string().optional(),
  entryFees: z.number().multipleOf(0.0001),
  startDate: z.string().datetime().nullable(),
  endDate: z.string().datetime().nullable(),
  minAmount: z.number().multipleOf(0.01),
  maxAmount: z.number().multipleOf(0.01),
  asset: z.string(),
  custodyFees: z.number().nonnegative().multipleOf(0.0001),
  lockupPeriod: z.number().multipleOf(1),
});

export const CustodyFeesSchema = z.object({
  id: z.string().uuid(),
  userId: z.string(),
  orderId: z.string(),
  status: CustodyFeesPeriodStatusEnumSchema,
  period: z.number().int(),
  remainingAmountAsset: z.number().multipleOf(0.0001),
  custodyFeesDue: z.number().multipleOf(0.0001),
  custodyFeesPaid: z.number().multipleOf(0.0001),
  startDate: z.string().datetime(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const OrderSchemaPlain = z.object({
  id: z.string().uuid(),
  status: OrderStatusSchema,
  userId: z.string().uuid(),
  batchId: z.string().uuid(),
  type: OrderTypeSchema,
  asset: z.string(),
  amountAsset: z.number(),
  amountBond: z.number().int(),
  amountEUR: z.number().multipleOf(0.0001),
  entryFees: z.number().multipleOf(0.0001),
  assetMarketPrice: z.number().multipleOf(0.01),
  contractGeneratedAt: z.string().datetime(),
  contractSignedAt: z.string().datetime(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const OrderSchema = OrderSchemaPlain.extend({
  batch: BatchSchema,
  custodyFees: z.array(CustodyFeesSchema),
  sellOrders: z.array(OrderSchemaPlain),
});

export type Order = z.infer<typeof OrderSchema>;
