import { useMutation, useQuery, type UseQueryReturnType } from '@tanstack/vue-query';
import { computed, type Ref } from 'vue';
import { z } from 'zod';

import { API_QUERY_KEY_USER_BUY_ORDERS } from '@/constants/api-query-key-constants.ts';
import { useAuth } from '@/hooks/auth/use-auth.ts';
import { useApi } from '@/hooks/use-api.ts';
import { validateApiSchema } from '@/lib/zod-helpers.ts';
import ApiError from '@/models/api/api-error.ts';
import { type BuyOrder, BuyOrderSchema } from '@/schemas/users/buy-orders-schema.ts';

export function useUserBuyOrdersQuery(
  userId: Ref<string | undefined | null>,
): UseQueryReturnType<BuyOrder[], Error> {
  const api = useApi();
  const { isAuthenticated } = useAuth();

  const isQueryEnabled = computed(() => isAuthenticated.value && userId.value !== null);

  return useQuery<BuyOrder[], Error>({
    queryKey: [API_QUERY_KEY_USER_BUY_ORDERS, userId],
    queryFn: async ({ queryKey }) => {
      try {
        const [, id] = queryKey;
        const response = await api.get<BuyOrder[]>(`/users/${id as string}/orders/buy`);
        validateApiSchema(z.array(BuyOrderSchema), response.data);

        return response.data;
      } catch (error) {
        throw error as ApiError;
      }
    },
    enabled: isQueryEnabled,
    meta: { authRequired: true },
  });
}

// Mutation variant just for async
export function useUserBuyOrdersMutation() {
  const api = useApi();

  return useMutation<BuyOrder[], ApiError, string>({
    mutationFn: async (userId: string) => {
      const response = await api.get<BuyOrder[]>(`/users/${userId}/orders/buy`);
      validateApiSchema(z.array(BuyOrderSchema), response.data);

      return response.data;
    },
  });
}
